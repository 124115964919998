import React from 'react'
import Layout from '../components/Layout'
import Terms from '../components/Terms'

export default () => {

    return (
        <Layout title="Terms of Service - Epicron Digital">
            <Terms />
        </Layout>
    )
}